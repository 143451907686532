<template>
  <div id="newsStoryContent">
    <!-- pic7134F154C6A5D2AD6F771796089DF0F9.jpg -->
    <img id="Image1" src="" class="rounded">
    <p id="Text1">
      While we’re busy getting ready for one of the gaming highlights of the year, we can
      proudly announce that LEGO Universe has officially gone gold. This means the game
      has surpassed quality standards and is being prepped for mass distribution this fall!
      <br><br>
      All the creative content you can imagine being featured in a LEGO MMOG is really what
      you’ll find in LEGO Universe. And we’re super excited to share great gameplay and a
      gripping storyline with everyone at PAX (Penny Arcade Expo) in Seattle this coming weekend.
      <br><br>
      No matter whether you’re a hardcore gamer or new to the industry, stop by and give
      LEGO Universe a go on one our 7 demo stations at either Gazillion’s booth #3433 or
      Warner Bros.’ booth #3425. Special-edition giveaways are up for grabs, and members of
      our development team are available for inspiring chats on the show floor.
      <br><br>
      Currently in closed beta for PC and Mac, LEGO Universe will ship to North American
      retail stores on October 26, 2010 with an ESRB rating of “E10+”. We can’t wait, can you?
    </p>
  </div>
</template>
